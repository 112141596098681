<template>
  <div style="width: 100%;height: calc(100% - 28px);overflow: hidden;">
    <div style="width: 100%;height: 100%;">
      <!-- 输入框部分 -->
      <div id="search" class="search-container">
        <el-form :inline="true">
          <!-- 1. 获取最新特征数据的设置 -->
          <el-form-item>
            <span style="margin-left: 5px;">分析周期</span>
            <el-input v-model="cycle" placeholder="日" clearable style="width:70px;"></el-input>
            <span style="margin-left: 6px;">日</span>
          </el-form-item>
          <el-form-item>
            <span style="margin-left: 5px;">连续天数</span>
            <el-input v-model="continuousDays" placeholder="天" clearable style="width:64px;"></el-input>
            <span style="margin-left: 6px;">天</span>
          </el-form-item>
          <el-form-item>
            <span style="margin-left: 5px;">特征日</span>
            <!-- <div class="block">  </div> -->
            <el-date-picker v-model="judgeDate" placeholder="选择日期" size="mini" style="width: 126px;">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select v-model="position" clearable placeholder="位置" size="mini" style="width: 70px;">
              <el-option v-for="item in positOptions" :key="item.position" :label="item.label" :value="item.position">
              </el-option>
            </el-select>
            <!-- 待完成放量数据的涨跌筛选 -->
            <el-select v-model="priceUpDown" clearable placeholder="升降" size="mini"
              style="margin-left: 5px; width: 60px;">
              <el-option v-for="item in upDownOptions" :key="item.priceUpDown" :label="item.label"
                :value="item.priceUpDown">
              </el-option>
            </el-select>
            <el-button type="primary" plain @click="getAnalyzeDataList" size="mini" style="margin-left: 10px;">最新特征
            </el-button>
          </el-form-item>

          <!-- 2. 对选择的code进行数据分析的设置 -->
          <el-form-item>
            <el-divider direction="vertical"></el-divider>
            <span style="margin-left: 2px;">代码</span>
            <el-input v-model="analyzeCode" placeholder="代码或名称" clearable style="width:100px;"></el-input>
          </el-form-item>
          <el-form-item>
            <span style="margin-left: 2px;">时段</span>
            <el-date-picker v-model="periodValue" type="daterange" align="right" unlink-panels format="yyyy-MM-dd"
              value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
              :picker-options="pickerOptions" size="mini" style="width: 210px;"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" plain @click="graphAnalyze">分析</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 列表部分 -->
      <div id="d1" style="width: 228px; float:left;">
        <div id="table" class="mini-table-container">
          <el-table ref="singleTable" :data="codeTable" highlight-current-row @current-change="handleCurrentChange"
            tooltip-effect="dark" :height="tableHeight" :header-row-style="{ height: '30px' }"
            :header-cell-style="{ background: '#f5f7fa', padding: '0px' }" :row-style="rowStyle"
            :cell-style="{ padding: 0 }" style="width: 100%" border stripe>
            <el-table-column label="序号" align="center" width="50">
              <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column prop="tdate" label="tdate" v-if="false"></el-table-column>
            <el-table-column prop="code" label="代码" align="center" width="76"></el-table-column>
            <el-table-column prop="name" label="名称" align="center" width="84"></el-table-column>
          </el-table>
        </div>
      </div>

      <!-- 图形部分 -->
      <!-- hp notebook -->
      <div id="d2" style="width: 970px;  float:left;">
        <!-- <div style="margin-left:20px;margin-top:10px; height:20px;">详情：</div> -->
        <!-- <div id="mychart1" :style="{ width: '970px', height: '240px' }"></div> -->
        <!-- <div id="mychart2" :style="{ width: '970px', height: '240px' }"></div> -->
        <!-- <div id="mychart3" :style="{ width: '1400px', height: '800px' }"></div> -->
        <div id="mychart3" :style="{ width: '900px', height: '560px' }"></div>
        <!-- lenvon pc  -->
        <!-- <div id="d2" style="width: 1440px;  float:left;">
      <div style="margin-left:20px;margin-top:10px; height:20px;">详情：</div>
      <div id="mychart1" :style="{ width: '1024px', height: '300px' }"></div>
      <div id="mychart2" :style="{ width: '1024px', height: '300px' }"></div>   -->
        <!-- <el-row :gutter="1">
            <el-col :span="3.5">
              <div class="grid-content bg-purple"></div>
            </el-col>
            <el-col :span="20">
                <div  style="height:100px;">详情：</div>
                <div id="mychart1" :style="{width:'1024px',height:'300px'}"></div>
                <div id="mychart2" :style="{width:'1024px',height:'300px'}"></div>
            </el-col>
          </el-row> -->
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "Character",
  data() {
    return {
      analyzeCode: "",
      currentRow: { code: "", tdate: "", name: "" },
      cycle: 3,
      continuousDays: 5,
      judgeDate: new Date(),
      positOptions: [{
        position: '1',
        label: '高位'
      }, {
        position: '2',
        label: '中位'
      }, {
        position: '3',
        label: '低位'
      }],
      upDownOptions: [{
        priceUpDown: '1',
        label: '涨'
      }, {
        priceUpDown: '2',
        label: '跌'
      }],
      position: '',
      priceUpDown: '',
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近两年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 2);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      periodValue: [new Date().getNextDate(-366), new Date().format("yyyy-MM-dd")],
      tableHeight: undefined,
      startDate: null,
      endDate: null,

      //给定code在给定时段内的所有特征值
      maxPriceCoord: null,
      minPriceCoord: null,
      intervalCoord: null,
      charactLst: [],
      codeTable: [],
      //图形标题
      nameCodeTitle: '',
      klineLst: null
    };
  },
  created() {
    this.tableHeight = window.innerHeight - 60 - 36 - 32 - 10;
    this.startDate = new Date().getNextDate(-366);
    this.endDate = new Date().format("yyyy-MM-dd");
    //1.获取具有特征值的所有code 
    this.getAnalyzeDataList();
  },
  methods: {
    rowStyle() {
      return { height: "34px" };
    },
    handleCurrentChange(val) {
      if (val) {
        this.currentRow = val;
        this.analyzeCode = this.currentRow.code;
      }
    },

    //1. 获取均线在指定周期内连续几天上升的code列表
    getAnalyzeDataList() {
      // console.log("========= position ===========")
      // console.log("position: " + this.position)
      this.$axios
        .post("/dataanalyze/allcodejx", {
          cycle: this.cycle,
          judgeDate: this.judgeDate,
          continuousDays: this.continuousDays,
          position: this.position,
          priceUpDown: this.priceUpDown
        })
        .then(res => {
          this.codeTable = res.data.data;
        });
    },

    //2. 图形分析
    graphAnalyze() {
      // console.log("****************graphAnalyze()****************")
      if (this.analyzeCode != "") {
        this.startDate = this.periodValue[0];
        this.endDate = this.periodValue[1];
        this.getAllGraphData();
      } else {
        alert("需要选择或输入code！")
      }
    },

    //3. 绘制选中代码的图形
    getAllGraphData() {
      //3.1 获取给定code 在给定时段内的所有特征值数据
      this.$axios.post("/dataanalyze/singlecodecjl", {
        codeOrNm: this.analyzeCode,
        cycle: this.cycle,
        continuousDays: this.continuousDays,
        startDate: this.periodValue[0],
        endDate: this.periodValue[1]
      }).then(res => {
        this.charactLst = res.data.data;
        //3.2 获取给定code在给定时段内的所有图形数据
        this.getLineData();
      }).catch(error => {
        this.getLineData();
        console.log(error)       //请求失败返回的数据
      });
    },

    getLineData() {
      this.$axios.post("/showwy/jxgraphdatadetail", {
        // this.$axios.post("/showwy/linedata", {
        codeOrNm: this.analyzeCode,
        startDate: this.periodValue[0],
        endDate: this.periodValue[1]
      })
        .then(res => {
          console.log("========= res.data.data value========")
          this.klineLst = res.data.data.klineArrayLst;
          this.nameCodeTitle = res.data.data.name + " - " + res.data.data.code
          // console.log("this.klineLst: " , this.klineLst)
          // console.log(" this.nameCodeTitle: " , this.nameCodeTitle)
          this.drawLineChart();
        });
    },

    drawLineChart() {
      // 基于准备好的dom，初始化echarts实例
      let myChart3 = this.$echarts.init(document.getElementById("mychart3"));

      const upColor = '#00da3c';
      const downColor = '#ec0000';
      function splitData(rawData) {
        let categoryData = [];
        let values = [];
        let volumes = [];
        for (let i = 0; i < rawData.length; i++) {
          categoryData.push(rawData[i].splice(0, 1)[0]);
          values.push(rawData[i]);
          volumes.push([i, rawData[i][4], rawData[i][0] > rawData[i][1] ? 1 : -1]);
        }
        return {
          categoryData: categoryData,
          values: values,
          volumes: volumes
        };
      }

      function calculateMA(dayCount, data) {
        var result = [];
        for (var i = 0, len = data.values.length; i < len; i++) {
          if (i < dayCount) {
            result.push('-');
            continue;
          }
          var sum = 0;
          for (var j = 0; j < dayCount; j++) {
            sum += data.values[i - j][1];
          }
          result.push(+(sum / dayCount).toFixed(3));
        }
        return result;
      }

      // #region 1. 绘制K线图
      // console.log("typeof this.intervalCoord: "+ typeof this.intervalCoord);
      // console.log("this.intervalCoord: "+ this.intervalCoord);
      var rawData = this.klineLst.reverse();
      var data = splitData(rawData);
      // console.log("=============-------rawData------=================")
      // console.log("this.klineLst: " , this.klineLst)
      // console.log("rawData: " , rawData)
      let xDataLen = data.categoryData.length;
      let zoomStart;
      if (xDataLen >= 480) {
        zoomStart = 80
      } else if (xDataLen >= 240) {
        zoomStart = 70
      } else if (xDataLen >= 200) {
        zoomStart = 60
      } else if (xDataLen >= 160) {
        zoomStart = 50
      } else if (xDataLen >= 120) {
        zoomStart = 30
      } else if (xDataLen >= 80) {
        zoomStart = 20
      } else {
        zoomStart = 0
      }

      var option;   // this.nameCodeTitle 
      myChart3.setOption(
        (option = {
          animation: false,
          title: {
            left: 10,
            top: 10,
            text: this.nameCodeTitle
          },
          legend: {
            show: true,
            top: 10,
            left: 'center',
            data: ['candle', 'MA5', 'MA10', 'MA20', 'MA30'],
            selected: { 'MA10': false, 'MA20': false, 'MA30': false, } // 不需要显示的设置为false
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            },
            borderWidth: 1,
            borderColor: '#ccc',
            padding: 10,
            textStyle: {
              color: '#000'
            },
            position: function (pos, params, el, elRect, size) {
              const obj = {
                top: 10
              };
              obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
              return obj;
            },
            //  extraCssText: 'width:250px;height:400px;',//提示框大小
            extraCssText: 'width: 170px;background:white;'
          },
          axisPointer: {   //十字线
            link: [
              {
                xAxisIndex: 'all'
              }
            ],
            label: {
              backgroundColor: '#777'
            }
          },
          toolbox: {
            feature: {
              dataZoom: {
                yAxisIndex: false
              },
              brush: {
                type: ['lineX', 'clear']
              }
            }
          },
          brush: {
            xAxisIndex: 'all',
            brushLink: 'all',
            outOfBrush: {
              colorAlpha: 0.1
            }
          },
          visualMap: {
            show: false,
            seriesIndex: 5,
            dimension: 2,
            pieces: [
              {
                value: 1,
                color: downColor
              },
              {
                value: -1,
                color: upColor
              }
            ]
          },
          grid: [
            {
              left: '3%',
              right: '8%',
              height: '50%'
            },
            {
              left: '3%',
              right: '8%',
              top: '63%',
              height: '16%'
            }
          ],
          xAxis: [
            {
              type: 'category',
              data: data.categoryData,
              boundaryGap: false,
              axisLine: { onZero: false },
              splitLine: { show: false },
              min: 'dataMin',
              max: 'dataMax',
              axisPointer: {
                z: 100
              }
            },
            {
              type: 'category',
              gridIndex: 1,
              data: data.categoryData,
              boundaryGap: false,
              axisLine: { onZero: false },
              axisTick: { show: false },
              splitLine: { show: false },
              axisLabel: { show: false },
              min: 'dataMin',
              max: 'dataMax'
            }
          ],
          yAxis: [
            {
              scale: true,
              splitArea: {
                show: true
              }
            },
            {
              scale: true,
              gridIndex: 1,
              splitNumber: 2,
              axisLabel: { show: false },
              axisLine: { show: false },
              axisTick: { show: false },
              splitLine: { show: false }
            }
          ],
          dataZoom: [
            {
              type: 'inside',
              xAxisIndex: [0, 1],
              start: zoomStart,
              end: 100
            },
            {
              show: true,
              xAxisIndex: [0, 1],
              type: 'slider',
              top: '82%',
              start: 5,
              end: 100
            }
          ],
          series: [
            {
              name: 'candle',
              type: 'candlestick',
              data: data.values,
              itemStyle: {
                color: upColor,
                color0: downColor,
                borderColor: undefined,
                borderColor0: undefined
              },
              tooltip: {
                formatter: function (param) {
                  param = param[0];
                  return [
                    'Date: ' + param.name + '<hr size=1 style="margin: 3px 0">',
                    'Open: ' + param.data[0] + '<br/>',
                    'Close: ' + param.data[1] + '<br/>',
                    'Lowest: ' + param.data[2] + '<br/>',
                    'Highest: ' + param.data[3] + '<br/>'
                  ].join('');
                }
              }
            },
            {
              name: 'MA5',
              type: 'line',
              data: calculateMA(5, data),
              smooth: true,
              lineStyle: {
                opacity: 0.5
              }
            },
            {
              name: 'MA10',
              type: 'line',
              data: calculateMA(10, data),
              smooth: true,
              lineStyle: {
                opacity: 0.5
              }
            },
            {
              name: 'MA20',
              type: 'line',
              data: calculateMA(20, data),
              smooth: true,
              lineStyle: {
                opacity: 0.5
              }
            },
            {
              name: 'MA30',
              type: 'line',
              data: calculateMA(30, data),
              smooth: true,
              lineStyle: {
                opacity: 0.5
              }
            },
            {
              name: 'Volume',
              type: 'bar',
              xAxisIndex: 1,
              yAxisIndex: 1,
              data: data.volumes
            }
          ]
        }),
        true
      );

      let startPostion = data.categoryData[data.categoryData.length - parseInt((data.categoryData.length) * 0.16)];
      let endPostion = data.categoryData[data.categoryData.length - 1];
      // console.log(data.categoryData[data.categoryData.length-1]);
      // console.log(startPostion);
      // console.log(endPostion);

      myChart3.dispatchAction({
        type: 'takeGlobalCursor',
        key: 'brush',
        brushOption: {
          // 参见 brush 组件的 brushType。如果设置为 false 则关闭“可刷选状态”。
          brushType: 'lineX',
          // 参见 brush 组件的 brushMode。如果不设置，则取 brush 组件的 brushMode 设置。
          brushMode: 'single'
        },
        areas: [
          {
            brushType: 'lineX',
            coordRange: [startPostion, endPostion],
            xAxisIndex: 0
          }
        ]
      });
      // myChart3.dispatchAction({
      //     type: 'brush',
      //     areas: [
      //       {
      //         brushType: 'lineX',
      //         coordRange: [startPostion, endPostion],
      //         xAxisIndex: 0
      //       }
      //     ]
      // });

      // #region 
      // function calculateMA(dayCount, data) {
      //     var result = [];
      //     for (var i = 0, len = data.length; i < len; i++) {
      //       if (i < dayCount) {
      //         result.push('-');
      //         continue;
      //       }
      //       var sum = 0;
      //       for (var j = 0; j < dayCount; j++) {
      //         sum += +data[i - j][1];
      //       }
      //       result.push((sum/dayCount).toFixed(2));
      //     }
      //     return result;
      // }
      // //获取的横轴值，即 日期
      // const dates = rawData.map(function (item) {
      //     return item[0];
      // });
      // //获取蜡烛图的四个值：开盘、收盘、最大、最小值
      // const data = rawData.map(function (item) {
      //     return [+item[1], +item[2], +item[5], +item[6]];
      // });

      // myChart3.setOption({
      //   grid: {
      //       left: 50,
      //       right: 40,
      //       top: 30,
      //       bottom: 60
      //     },
      //     legend: {
      //       data: ['日K', 'MA5', 'MA10', 'MA20', 'MA30'],
      //       x:'center',
      //       y:' center',
      //       //padding:[0,30,0,0] [（上），（右）、（下）、（左）]
      //       padding:[26,30,0,0],
      //       inactiveColor: '#777'
      //     },
      //     tooltip: {
      //       trigger: 'axis',
      //       axisPointer: {
      //         animation: false,
      //         type: 'cross',
      //         lineStyle: {
      //           color: '#376df4',
      //           width: 2,
      //           opacity: 1
      //         }
      //       }
      //     },
      //     xAxis: {
      //       type: 'category',
      //       data: dates,
      //       axisLine: { lineStyle: { color: '#8392A5' } }
      //     },
      //     yAxis: {
      //       scale: true,
      //       axisLine: { lineStyle: { color: '#8392A5' } },
      //       splitLine: { show: false }
      //     },
      //     // 放大缩小滑块
      //     dataZoom: [
      //       {
      //         textStyle: {
      //           color: '#8392A5'
      //         },
      //         handleIcon:
      //           'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
      //         dataBackground: {
      //           areaStyle: {
      //             color: '#8392A5'
      //           },
      //           lineStyle: {
      //             opacity: 0.8,
      //             color: '#8392A5'
      //           }
      //         },
      //         brushSelect: true
      //       },
      //       {
      //         type: 'inside'
      //       }
      //     ],

      //     series: [
      //       {
      //         type: 'candlestick',
      //         name: 'Day',
      //         //获取蜡烛图的四个值：开盘、收盘、最大、最小值
      //         data: data,
      //         itemStyle: {
      //           color: '#FD1050',
      //           color0: '#0CF49B',
      //           borderColor: '#FD1050',
      //           borderColor0: '#0CF49B'
      //         }
      //       },
      //       {
      //         name: 'MA5',
      //         type: 'line',
      //         data: calculateMA(5, data),
      //         smooth: true,
      //         showSymbol: false,
      //         lineStyle: {
      //           width: 1
      //         }
      //       },
      //       {
      //         name: 'MA10',
      //         type: 'line',
      //         data: calculateMA(10, data),
      //         smooth: true,
      //         showSymbol: false,
      //         lineStyle: {
      //           width: 1
      //         }
      //       },
      //       {
      //         name: 'MA20',
      //         type: 'line',
      //         data: calculateMA(20, data),
      //         smooth: true,
      //         showSymbol: false,
      //         lineStyle: {
      //           width: 1
      //         }
      //       },
      //       {
      //         name: 'MA30',
      //         type: 'line',
      //         data: calculateMA(30, data),
      //         smooth: true,
      //         showSymbol: false,
      //         lineStyle: {
      //           width: 1
      //         }
      //       }
      //     ]
      // });
      // #endregion       

    },

  }
};

</script>

<style lang="less" scoped>
.el-divider {
  background-color: #b6d7fb;
  height: 20px;
  margin: 0 10px;
}

.search-container /deep/ .el-form-item {
  margin-bottom: 2px;
}

.search-container /deep/ .el-input__inner {
  height: 26px;
  margin-left: 4px;
  line-height: 26px;
}

.search-container /deep/ .el-button {
  padding: 5px 12px;
  margin-left: 4px;
}

.mini-table-container {
  width: 100%;
  height: calc(100% - 36px);
}

用来设置当前页面element全局table 选中某行时的背景色

/* .el-table__body tr.current-row>td{
    background-color: #f19944 !important;
      color: #f19944;  
  } */
.el-row {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #799bcc;
}

.bg-purple {
  background: #9bb9db;
}

.bg-purple-light {
  background: #e8ecf3;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>